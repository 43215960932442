import React, { createContext, useContext, useState, useEffect } from 'react';
import progressHandler from '../utils/progressHandler';
import './css/ProgressProvider.css';

const ProgressContext = createContext();

const ProgressProvider = ({ children }) => {
  const [progressMessage, setProgressMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [ellipsis, setEllipsis] = useState('');

  const showMessage = (msg) => {
    setProgressMessage(msg);
    setIsVisible(true);
  };

  const updateMessage = (msg) => {
    setProgressMessage(msg);
  };

  const hideMessage = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    // Set the handlers for global use
    progressHandler.setHandlers({ showMessage, updateMessage, hideMessage });
  }, []);

  useEffect(() => {
    if (isVisible) {
      const ellipsisCycle = ['.', '..', '...', '....'];
      let index = 0;

      const intervalId = setInterval(() => {
        setEllipsis(ellipsisCycle[index]);
        index = (index + 1) % ellipsisCycle.length;
      }, 500);

      // Clear the interval when isVisible becomes false
      return () => clearInterval(intervalId);
    } else {
      setEllipsis(''); // Reset ellipsis when the message is not visible
    }
  }, [isVisible]);

  return (
    <ProgressContext.Provider value={{ showMessage, updateMessage, hideMessage, progressMessage }}>
      {children}
      {/* {isVisible && (
        <div className={`progress-provider ${isVisible ? 'fade-in' : 'fade-out'}`}>
          <div className={`card`}>{message}{ellipsis}</div>
        </div>
      )} */}
    </ProgressContext.Provider>
  );
};

export default ProgressProvider;
