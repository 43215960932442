import axios from 'axios';
import NodeQuery from './nodeQuery';
import { UserContext } from '../providers/UserProvider';

const getBaseUrl = () => {
  const { hostname } = window.location;
  if (hostname === 'localhost' || hostname === '10.0.0.140') {
    return `http://${process.env.REACT_APP_IP_HOST}:${process.env.REACT_APP_NODE_PORT}/api`;
  }
  else if(hostname === 'galiant.duckdns.org') {
     return `http://${process.env.REACT_APP_PUBLIC_HOST}/api`
  }
  return `http://${process.env.REACT_APP_PUBLIC_HOST}/api`;
};

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  timeout: 300000,
  maxContentLength: Infinity,
  maxBodyLength: Infinity, 
});

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (newToken) => {
  refreshSubscribers.map(callback => callback(newToken));
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

// Add a response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    const userContext = UserContext._currentValue; // Access UserContext

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newToken = await NodeQuery.refreshToken(localStorage.getItem('token'));
          if (newToken) {
            userContext.setToken(newToken);
            localStorage.setItem('token', newToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            onRefreshed(newToken);
          } else {
            userContext.setToken(null);
            userContext.setUserId(null);
          }
        } catch (err) {
          console.error('Error refreshing token:', err);
        } finally {
          isRefreshing = false;
          refreshSubscribers = [];
        }
      }

      return new Promise((resolve) => {
        addRefreshSubscriber((newToken) => {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          resolve(axiosInstance(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
